.legend {
  padding: 0;
  list-style: none;
  margin-top: 50px;
}

.legend__item {
  padding: 0;
  margin: 0;
  text-indent: 0;
}

.legend__item-mark {
  width: 10px;
  height: 12px;
  display: inline-block;
  margin-right: 5px;
}
